import React from 'react';
import {useTranslation} from 'react-i18next';
import {Snackbar} from "@mui/material";
import MuiAlert from "@mui/lab/Alert";


export default function PDSSnackbar({
                                        open,
                                        onClose,
                                        message,
                                        severity,
                                    }) {
    const {t} = useTranslation();


    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={onClose}
                  anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
            <MuiAlert elevation={6} variant="filled" onClose={onClose} severity={severity}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

