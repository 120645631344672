import React, {useState, useEffect} from 'react';
import PropTypes, {string} from 'prop-types';
import MaterialTable from '@material-table/core';
import { makeStyles } from '@mui/styles';
import TablePagination from '@mui/material/TablePagination';

import {Loading} from '../../elements';
import {useTranslation} from 'react-i18next';
import tableIcons from './tableIcons';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        '& .MuiToolbar-root': {
            minHeight: 0,
        },

        '& .MuiTable-root': {
            borderCollapse: 'unset',
        },
    },
}));

function CustomTable(props) {
    const classes = useStyles();
    const {t} = useTranslation();
    const {
        isLoading,
        title,
        columns,
        data,
        count,
        loadAllData,
        options,
        pageNumber,
        rowsPerPage,
        handleChangeRowsPerPage,
        handleChangePage,
        onRowClick,
        handleOrderChange,
    } = props;

    const [pageSize, setPageSize] = useState(rowsPerPage || 5);
    const [initialPage, setInitialPage] = useState(0);

    const onOrderChange = (orderedColumnId, orderDirection) => {
        setInitialPage(0);
        handleOrderChange(orderedColumnId, orderDirection);
    };


    const onChangeRowsPerPage = (pageSize) => {
        setPageSize(pageSize);
        handleChangeRowsPerPage(pageSize);
    };

    const onChangePage = (page) => {
        setInitialPage(page);
        handleChangePage(page);
    };

    useEffect(() => {
        if (options.maxBodyHeight) {
            setInitialPage(0);
        }
    }, [options]);

    return (
        <div className={classes.root}>
            <MaterialTable
                title={title}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: t('NO_RECORD'),
                    },
                    pagination: {
                        firstTooltip: t('FIRST_PAGE'),
                        firstAriaLabel: t('FIRST_PAGE'),
                        previousTooltip: t('PREVIOUS_PAGE'),
                        previousAriaLabel: t('PREVIOUS_PAGE'),
                        nextTooltip: t('NEXT_PAGE'),
                        nextAriaLabel: t('NEXT_PAGE'),
                        labelDisplayedRows: t('ROWS_PER_PAGE'),
                        lastTooltip: t('LAST_PAGE'),
                        lastAriaLabel: t('LAST_PAGE'),
                        labelRows: t('ROWS_SELECT'),
                        labelRowsPerPage: '',
                    },
                    toolbar: {
                        exportName: 'Export',
                        searchTooltip: t('SEARCH_INNER'),
                        searchPlaceholder: t('SEARCH_INNER'),
                    }
                }}
                columns={columns}
                data={data}
                isLoading={isLoading}
                options={{
                    search: false,
                    paging: true,
                    loadingType: 'overlay',
                    showEmptyDataSourceMessage: !isLoading,
                    pageSize,
                    initialPage,
                    ...options,
                }}
                style={{
                    boxShadow: 'none',
                    padding: '0 10px',
                }}
                onOrderChange={handleOrderChange ? handleOrderChange : null}
                onRowClick={onRowClick}
                onChangeRowsPerPage={!loadAllData ? onChangeRowsPerPage : null}
                onChangePage={!loadAllData ? onChangePage : null}
                components={loadAllData ? {
                    OverlayLoading: () => (
                        <Loading/>
                    ),
                    Pagination: (props) => (
                        <TablePagination
                            {...props}
                            rowsPerPageOptions={[5, 10, 20, 30]}
                            rowsPerPage={loadAllData ? rowsPerPage : pageSize}
                            count={loadAllData ? count : data.length}
                            page={loadAllData ? pageNumber : initialPage}
                            onPageChange={(e, page) => onChangePage(page)}
                            onRowsPerPageChange={(event) => {
                                onChangeRowsPerPage(event.target.value);
                            }}
                        />
                    ),
                } : {
                    OverlayLoading: () => (
                        <Loading/>
                    ),
                }}
            />
        </div>
    );
}

CustomTable.propTypes = {
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    count: PropTypes.number,
    loadAllData: PropTypes.bool,
    options: PropTypes.object,
    rowsPerPage: PropTypes.number,
    pageNumber: PropTypes.number,
    handleChangeRowsPerPage: PropTypes.func,
    handleChangePage: PropTypes.func,
};

CustomTable.defaultProps = {
    isLoading: false,
    count: 0,
    loadAllData: false,
    title: '',
    options: {},
    rowsPerPage: 5,
    pageNumber: 0,
    handleChangeRowsPerPage: () => null,
    handleChangePage: () => null,
};

export default CustomTable;
