import types from '../actionTypes';

const INITIAL_STATE = {
  isFetchingAllRolesList : false,
  roles: [],
  errors: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ROLES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingAllRolesList: true,
      };
    case types.ROLES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAllRolesList: false,
        roles: action.payload.roles,
      };
    case types.ROLES_ACTIVE_SUCCESS:
      return {
        ...state,
        isFetchingAllRolesList: false,
      }
    case types.ROLES_FETCH_FAIL:
      return {
        ...state,
        isFetchingAllRolesList: false,
        errors: action.payload.error,
      };

    case types.ROLES_CREATE_SUCCESS:
      return {
          ...state, isCreating: false
      };
    case types.ROLES_CREATE_REQUEST:
      return {
          ...state, isCreating: true
      };
    case types.ROLES_CREATE_FAIL:
      return {
          ...state, 
          isCreating: false,
          errors: action.payload.error,
      };

    case types.ROLES_UPDATE_SUCCESS:
        return {
            ...state, isUpdating: false
        };
    case types.ROLES_UPDATE_REQUEST:
        return {
            ...state, isUpdating: true
        };
    case types.ROLES_UPDATE_FAIL:
        return {
            ...state, 
            isUpdating: false,
            errors: action.payload.error,
        };

    default:
      return state;
  }
};
